// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { bg } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="85vh"
      display="flex"
      flexDirection="column"
      style={{
        background: `linear-gradient(to bottom, rgba(200, 106, 255, 0.8) 10%, rgba(0, 150, 199, .9) 90%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container maxWidth="xl">
          <Box p={4} display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="center" maxWidth="60%" textAlign="center" mx="auto" sx={{backgroundColor: "rgba(182, 81, 252, 0.5)", borderRadius: "2rem"}}>
            <Typography variant="h3" gutterBottom style={{ fontWeight: "900" }}>
            Welcome to winvegasprobet!
            </Typography>
            <Typography variant="h5" gutterBottom style={{ fontWeight: "900" }}>
            Dive into a world of endless fun with our vast collection of games. Play instantly on any device without downloads or logins.

            </Typography>

            <Typography variant="p2" gutterBottom style={{ fontWeight: "900" }}>
            Practicing casino gaming for fun does not imply future success in real money gambling.
            </Typography>
            <Typography variant="p2" gutterBottom style={{ fontWeight: "900" }}>
            Does not involve the use of Real Money.            </Typography>
          </Box>
      </Container>
    </Box>
  );
}

export default Hero;
