var title = [
    "2016 Gladiators",
    "Tower Quest",
    "4 of a King",
    "5 Reel Drive",
    "5xMagic",
    "A Christmas Carol",
    "Ace Of Spades",
    "Big Blox",
    "Crystal Clans",
    "Platoon Wild",
    "Royal Mint Megaways",
    "Sic Bo",
    "Spinfinity Man",
    "Stacks O’ Gold",
    "Super Diamond Wild",
    "The Wiz",
    "Joker King",
    "Queen’s day tilt",
    "Xmas Joker",
    "Wild North",
    "Royal Masquerade",
    "Rage To Riches",
    "Pimped",
    "Happy Halloween",
    "Golden Caravan",
    "Fire Joker",
    "Eye Of The Kraken",
    "Easter Eggs",
    "Cloud Quest",
    "Wizard Of Gems",
    "Super Flip",
    "Samba Carnival",
    "Sails Of Gold",
    "Money Wheel Kasyno",
    "Mermaids Diamond",
    "Leprechaun Goes To Hell",
    "Jade Magician",
    "Holiday Season",
    "Grim Muerto",
    "Bugs Party",
    "Aztec Warrior Princess",
    "Viking Runecraft",
    "Sweet Alchemy",
    "Reactoonz",
    "Prosperity Palace",
    "Prissy Princess",
    "Planet Fortune ",
    "Moon Princess",
    "Legacy Of Egypt",
    "House Of Doom",
    "Gold King",
    "European Roulette Pro",
    "Big Win Cat"
];

var links = [
    "oM82D1p",
    "W1r5FkD",
    "gM82ZHk",
    "qM82Cc6",
    "VM82NJO",
    "HM821SP",
    "hM822Lt",
    "9M8239W",
    "VM827lI",
    "HM826wm",
    "tM89qOd",
    "wM89ejA",
    "gM89rHk",
    "oM89yxC",
    "FM89ost",
    "dM89abK",
    "5M89dCp",
    "xM89gCf",
    "aM89jRC",
    "KM89lyc",
    "g1r6THk",
    "h1r6N4D",
    "M1tqj43",
    "Q1tqAVG",
    "q1tqNvs",
    "y1twsgO",
    "g1twPV0",
    "41twN85",
    "L1teYEv",
    "g1tyriE",
    "q1tyrhY",
    "P1tyrvI",
    "91tyrAn",
    "61tyrL6",
    "v1tyrNP",
    "Q1tybkp",
    "a1tybmc",
    "g1tybPE",
    "V1tybKf",
    "h1tybB7",
    "M1tyb1w",
    "W1tpgx4",
    "r1tpgQn",
    "g1tpgDp",
    "V1tpgXe",
    "j1tpg34",
    "Z1tphiB",
    "S1tpYQO",
    "z1tpQYO",
    "Q1tpQAa",
    "I1tpQLN",
    "D1tpQCs",
    "f1tpQ3p"
];

var gamesArray = [];

for (var i = 0; i < title.length; i++) {
  // var imageName = title[i].replace(/\s+/g, '');
    var game = {
        id: i + 1,
        title: title[i],
        image: `games/${[i + 1]}.jpeg`, 
        iframeUrl: "https://cutt.ly/" + links[i] 
    };
    gamesArray.push(game);
}

function getRandomGames(gamesArray, maxGames) {
  var selectedGames = [];
  gamesArray.sort(() => Math.random() - 0.5);
  var numGamesToSelect = Math.min(maxGames, gamesArray.length);
  for (var i = 0; i < numGamesToSelect; i++) {
      selectedGames.push(gamesArray[i]);
  }
  return selectedGames;
}

var maxGames = 16;
const games = getRandomGames(gamesArray, maxGames);

const topGames = getRandomGames(games, 3);


export {games, topGames};
