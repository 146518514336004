import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {bg} from "../assets";

function Copyright() {
  return (
    <>
    <Typography variant="body2" gutterBottom>
      {'Copyright © '}
      winvegasprobet {new Date().getFullYear()}{'.'} <br/>
      </Typography>  
        <Typography variant="body1" textTransform="uppercase">

      Does not involve the use of Real Money. <br/>
      Practicing casino gaming for fun does not imply future success in real money gambling. <br/>
      Is intended for persons over 18 years of age and is for entertainment purposes only.   </Typography>
      </>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
          backgroundColor: "#5a2dab"
        }} 
      >
        <Container maxWidth="sm">
        <Typography variant="body1">info@winvegasprobet.com</Typography>
        <Typography variant="body1">2030 Harrison St, San Francisco, CA 94110, United States</Typography>
        <Typography variant="body1">+14155031600</Typography>
        <br/>
        <hr style={{border: "0.1px solid #ffffff"}}/>
        <br/>

          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy{' '}
            </Link>
            |{' '}
            <Link to="/contacts"  style={{color: "#ffffff", textDecoration: "none"}}>
              Contacts
            </Link>
          </Typography>
          <Copyright />
        </Container>
      </Box>
  );
}
