// src/components/GameDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {games} from '../data/games.js'; // Adjust the path to match your project structure
import Header from "./Header";

function GamePage() {
  const { gameId } = useParams(); // Retrieve the game ID from the route parameter

  // Find the selected game based on the game ID
  const selectedGame = games.find((game) => game.id === parseInt(gameId));

  if (!selectedGame) {
    return <div>Game not found</div>;
  }

  return (
    <div>
      <Header/>
      <div style={{paddingTop: "1rem"}}>
        <Typography variant="h4" align="center" gutterBottom>
          {selectedGame.title}
        </Typography>
        <iframe
          title={selectedGame.title}
          src={selectedGame.iframeUrl}
          width="100%"
          height="500"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default GamePage;
