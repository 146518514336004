import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Form from './Form';
import Header from './Header';
import {contactUs} from "../assets";

export default function Contacts() {
  return (
    <div>
      <Header/>
    <Container maxWidth="lg" id="contacts">
      <Typography variant="h4" textAlign="center" gutterBottom>Have Any Question?</Typography>
      <Typography variant="h6" textAlign="center" gutterBottom>Leave Us a message and we will answer you soon!</Typography>

      
          <Form/>
    </Container>
    </div>
  )
}
